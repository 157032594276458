import { Helmet } from "react-helmet";
import Hero from "../components/homeComps/Hero";
// import Courses from "../components/homeComps/Courses";
import Fatawa from "../components/homeComps/Fatawa";
import NewsLetter from "../components/homeComps/NewsLetter";
function Home() {
  return (
    <>
      <Helmet>
        <title>Noor Centre | Online Islamic Learning Platform</title>
      </Helmet>
      <Hero />
      {/* <Courses /> */}
      <Fatawa />
      <NewsLetter />
    </>
  );
}

export default Home;
