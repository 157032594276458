import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from 'moment'




// eslint-disable-next-line react/prop-types
function CourseCard({ course }) {
  const { t } = useTranslation();
  if (course.course_heading === '' || course.course_heading == null) {
    course.course_heading = t('missHead');
  }
  // if (description === '' || description == null) {
  //     description = t('missDesc');
  // }
  // if (link === '' || link == null ) {
  //     link = '#';
  // }


function category(course_category) { 
if (course_category === 'Paid') {
  return <p className="text-white dark:text-black px-2 py-1 rounded-md bg-orange-400 text-center text-md font-bold my-2 w-24">
  {course_category}
</p>
} else if(course_category === 'Free') {
  return <p className="text-white dark:text-black px-2 py-1 rounded-md bg-yellow-600 text-center text-md font-bold my-2 w-24">
  {course_category}
</p>
}else if(course_category === 'New') {
  return <p className="text-white dark:text-black px-2 py-1 rounded-md bg-yellow-400 text-center text-md font-bold my-2 w-24">
  {course_category}
</p> 
}else if(course_category === 'Popular') {
  return <p className="text-white dark:text-black px-2 py-1 rounded-md bg-green-400 text-center text-md font-bold my-2 w-24">
  {course_category}
</p>
}else if(course_category === 'Islamic') {
  return <p className="text-white dark:text-black px-2 py-1 rounded-md bg-green-600 text-center text-md font-bold my-2 w-24">
  {course_category}
</p>
}else if(course_category === 'Others') {
  return <p className="text-white dark:text-black px-2 py-1 rounded-md bg-gray-400 text-center text-md font-bold my-2 w-24">
  {course_category}
</p>
}

 }
  return (
    <>
      {/* <div className="max-w-sm bg-white border border-gray-700 rounded-lg shadow dark:bg-Dark-800 dark:border-gray-500">
        <Link to={link}>
          <img
            className="rounded-t-lg"
            src="https://flowbite.com/docs/images/blog/image-1.jpg"
            alt=""
          />
        </Link>
        <div className="p-5">
          <Link to={link}>
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {heading}
            </h5>
          </Link>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {description}
          </p>
          <Link
            to={link}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Read more
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </Link>
        </div>
      </div> */}


      <div className="max-w-sm bg-white border border-gray-700 rounded-lg shadow dark:bg-Dark-800 dark:border-gray-500">

        <img
          className="rounded-t-lg object-cover object-center"
          src={course.course_image}
          alt={course.course_heading}
        />

        <div className="p-5">


          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {course.course_heading}
          </h5>

          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {course.course_sub_heading}
          </p>

          <div className="flex items-center mt-4">

            <p className="text-gray-900 text-lg dark:text-white font-black">
              ${course.course_fee}
            </p>

            <p className="text-gray-700 dark:text-gray-400 text-lg mx-2 line-through">
              ${course.course_original_fee}
            </p>

          </div>

          
          {category(course.course_category)}

          <p className="text-gray-700 dark:text-gray-400 text-sm">
            Starts at {moment(course.course_start_date).format('MMMM Do YYYY')}
          </p>

          <div className="flex justify-end mt-4 space-x-2">

            <button
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-purple-700 rounded-lg hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300"
            >
              Enroll
            </button>

            <Link to={`/courses/${course.id}`}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Details
            </Link>

          </div>
        </div>

      </div>
    </>
  );
}

export default CourseCard;
