import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import React, { useState, useEffect } from 'react';
import api from '../api/api';
import Loading from '../components/Loading';
import FatwaCardEng from "../components/FatwaCardEng";
import FatwaCardUr from "../components/FatwaCardUr";
import Categories from "../components/Category";
function DarUlIfta() {

  const [fatawaEng, setFatawaEng] = useState([]);
  const [fatawaUr, setFatawaUr] = useState([]);
  const [status, setStatus] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    const response = await api.get('/fatwacate');
    setCategories(response.data)
  }

  const fetchFatawa = async () => {
    try {
      const response = await api.get('/darulifta');
      setStatus(response.data.status);
      setFatawaEng(response.data.fatawaEng);
      setFatawaUr(response.data.fatawaUr);


    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchFatawa();
    fetchCategories();
  }, []);

  if (!status && !categories && !fatawaEng && !fatawaUr) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Dar Ul Ifta - Noor Centre</title>
      </Helmet>
      <section className="grid grid-cols-12 gap-2 mt-6 ">

        <div className="flex flex-col col-span-8 gap-4 p-4 ">
          <div dir="rtl" className=" w-full bg-gray-200 border border-gray-500 rounded-lg dark:bg-Dark-800 ">
            <div className="p-3">
              <Link to="#">
                <h5 className="py-4 text-2xl font-extrabold tracking-tight underline text-gray-900 dark:text-white text-center">اردو فتاوی</h5>
              </Link>
              {fatawaUr.map((fatawaUr, i) => (
                <FatwaCardUr key={i} fatwa={fatawaUr} />
              ))}

            </div>
          </div>
          <div dir="ltr" className="w-full bg-gray-200 border border-gray-500 rounded-lg dark:bg-Dark-800 ">
            <div className="p-3">
              <Link to="#">
                <h5 className="py-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white underline text-center">English Fatawa</h5>
              </Link>
              {fatawaEng.map((fatawaEng, i) => (
                <FatwaCardEng key={i} fatwa={fatawaEng} />
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col col-span-4 gap-4 p-4 ">
          <div className="lg:max-w-sm w-full bg-gray-200 border border-gray-500 rounded-lg dark:bg-Dark-700 ">
            <div className="p-5">
              <Link to="#">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white underline text-center">
                  Recent Questions</h5>
              </Link>
              <ul className="px-6 list list-disc text-xl font-bold text-gray-900 dark:text-white underline">
                <li>abc</li>
                <li>def</li>
                <li>ghi</li>
              </ul>
            </div>
          </div>
          <div className="lg:max-w-sm w-full  bg-gray-200 border border-gray-500 rounded-lg dark:bg-Dark-700 ">
            <div className="p-5">
              <Link to="#">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white underline text-center">
                  Categories</h5>
              </Link>
              <Categories categories={categories} />
            </div>
          </div>

        </div>

      </section>

    </>
  )
}

export default DarUlIfta