import { Helmet } from "react-helmet"

function About() {
  return (
    <>
    <Helmet>
      <title>About - Noor Centre</title>
    </Helmet>
    <div>About</div>
    </>
  )
}

export default About