import React, { useState, useEffect } from 'react';
import { useParams,Link } from 'react-router-dom'
import  api from '../api/api'
import Loading from '../components/Loading';


function CoursePage() {
    const [course, setCourse] = useState(null);
    const { id } = useParams();
  
    const fetchCourse = async (id) => {
      try {
        const response = await api.get(`/courses/${id}`);
        setCourse(response.data.course);
      } catch (error) {
       console.error(error);
      }
    }
    
    useEffect(() => {
      fetchCourse(id);
    }, [id]);

  if (!course) {
    return <Loading />;
  }



  return (
    <>
    <div className="nav-tab-wrapper tabs  p-8 section-padding">
  <div className="container">
    <div className="grid grid-cols-12 gap-[30px]">
      <div className="lg:col-span-8 col-span-12">
        <div className="single-course-details">
          <div className="xl:h-[470px] h-[350px] mb-10 course-main-thumb">
            <img src="assets/images/all-img/single-course-thumb.png" alt=" " className=" rounded-md object-fut w-full h-full block" />
          </div>
          <div className=" mb-6">
            <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white ">Data Science</span>
          </div>
          <h2>UI/UX Design and Graphics Learning Bootcamp 2022</h2>
          <div className="author-meta mt-6 sm:flex  lg:space-x-16 sm:space-x-5 space-y-5 sm:space-y-0 items-center">
            <div className="flex space-x-4 items-center group">
              <div className="flex-none">
                <div className="h-12 w-12 rounded">
                  <img src="assets/images/all-img/author-1.png" alt=" " className=" object-cover w-full h-full rounded" />
                </div>
              </div>
              <div className="flex-1">
                <span className=" text-secondary  ">Trainer
                  <Link to="#" className=" text-black">
                    : Md Shamim Hossain</Link>
                </span>
              </div>
            </div>
            <div>
              <span className=" text-secondary  ">Last Update:
                <Link to="#" className=" text-black">
                  10 February, 2022</Link>
              </span>
            </div>
          </div>
          <div className="nav-tab-wrapper mt-12">
            <ul id="tabs-nav" className=" course-tab mb-8">
              <li className="active">
                <Link to="#tab1">
                  Overview
                </Link>
              </li>
              <li>
                <Link to="#tab2">
                  Carriculum
                </Link>
              </li>
              <li>
                <Link to="#tab3">
                  Instructor
                </Link>
              </li>
              <li>
                <Link to="#tab4">
                  Reviews
                </Link>
              </li>
            </ul>
            <div id="tabs-content">
              <div id="tab1" className="tab-content" style={{}}>
                <div>
                  <h3 className=" text-2xl">Course Description</h3>
                  <p className="mt-4">
                    This tutorial will help you learn quickly and thoroughly. Lorem ipsum, or lipsum as it sometimes known, is dummy text
                    used in laying out print, graphic or web designs. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.
                    Quisque volutpat mattis eros.
                    <br /> <br /> You’ll be exposed to principles and strategies, but, more importantly, you’ll learn how actually apply these
                    abstract concepts by coding three different websites for three very different the audiences. Lorem ipsum is dummy text
                    used in laying out print, graphic or web designs Lorem ipsum blinding shot chinwag knees.
                  </p>
                  <div className="bg-[#F8F8F8] space-y-6 p-8 rounded-md my-8">
                    <h4 className=" text-2xl">What You will Learn?</h4>
                    <ul className=" grid sm:grid-cols-2 grid-cols-1 gap-6">
                      <li className=" flex space-x-3">
                        <div className="flex-none  relative top-1 ">
                          <img src="" alt=" " />
                        </div>
                        <div className="flex-1">
                          Learn how perspective works and how to incorporate your art
                        </div>
                      </li>
                      <li className=" flex space-x-3">
                        <div className="flex-none  relative top-1 ">
                          <img src="" alt=" " />
                        </div>
                        <div className="flex-1">
                          Learn how perspective works and how to incorporate your art
                        </div>
                      </li>
                      <li className=" flex space-x-3">
                        <div className="flex-none  relative top-1 ">
                          <img src="" alt=" " />
                        </div>
                        <div className="flex-1">
                          Learn how perspective works and how to incorporate your art
                        </div>
                      </li>
                      <li className=" flex space-x-3">
                        <div className="flex-none  relative top-1 ">
                          <img src="" alt=" " />
                        </div>
                        <div className="flex-1">
                          Learn how perspective works and how to incorporate your art
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4 className=" text-2xl">What You will Learn?</h4>
                    <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5 mt-5">
                      <div className=" bg-white  rounded px-5 py-[18px] flex   shadow-box2 space-x-[10px] items-center">
                        <span className="flex-none">
                          <img src="" alt=" " />
                        </span>
                        <span className="flex-1 text-black">
                          Computer/Mobile
                        </span>
                      </div>
                      <div className=" bg-white  rounded px-5 py-[18px] flex  shadow-box2 space-x-[10px] items-center">
                        <span className="flex-none">
                          <img src="" alt=" " />
                        </span>
                        <span className="flex-1 text-black">
                          Paper &amp; Pencil
                        </span>
                      </div>
                      <div className=" bg-white  rounded px-5 py-[18px] flex  shadow-box2 space-x-[10px] items-center">
                        <span className="flex-none">
                          <img src="" alt=" " />
                        </span>
                        <span className="flex-1 text-black">
                          Internet Connect
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="tab2" className="tab-content" style={{display: 'none'}}>
                <div>
                  <h3 className=" text-2xl">Course Description</h3>
                  <div className="md:flex md:space-x-10  space-x-3 flex-wrap mt-4 mb-6">
                    <span>Level Beginner</span>
                    <span>12 Lectures</span>
                    <span>Total: 5 Hours 56 Minutes 24 Seconds</span>
                  </div>
                  <ul className="list  course-accrodain space-y-6">
                    <li className="open">
                      <button type="button" className="accrodain-button">
                        <span className="icon-pm fle x-none" />
                        <span className=" flex-1">Introduction</span>
                        <div className="flex-none extra-text  hidden sm:block">
                          3 Lectures, 34:51 min
                        </div>
                      </button>
                      <div className="content">
                        <div className=" text-xl font-semibold text-black mb-2">Intermediate Level</div>
                        <p>
                          This tutorial will help you learn quickly and thoroughly. Lorem ipsum is dummy text used in laying out print, graphic or
                          web designs
                        </p>
                        <div className=" text-xl font-semibold text-black mb-2 mt-6">2 Videos, 1 Audio, 1 Notebook</div>
                        <div className=" mt-8 ">
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Video: Getting Started and Introductions
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <span className=" bg-primary text-white px-3 rounded">Preview</span>
                              <img src="" alt=" " />
                            </div>
                          </Link>
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Video: Getting Started and Introductions
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <span className=" bg-primary text-white px-3 rounded">Preview</span>
                              <img src="" alt=" " />
                            </div>
                          </Link>
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Audio: Connectig Throgh Technology
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <img src="" alt=" " />
                            </div>
                          </Link>
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Video: Connectig Throgh Technology
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <img src="" alt=" " />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li>
                      <button type="button" className="accrodain-button">
                        <span className="icon-pm fle x-none" />
                        <span className=" flex-1">Getting Started</span>
                        <div className=" flex-none extra-text hidden sm:block ">
                          3 Lectures, 34:51 min
                        </div>
                      </button>
                      <div className="content hidden">
                        <div className=" text-xl font-semibold text-black mb-2">Intermediate Level</div>
                        <p>
                          This tutorial will help you learn quickly and thoroughly. Lorem ipsum is dummy text used in laying out print, graphic or
                          web designs
                        </p>
                        <div className=" text-xl font-semibold text-black mb-2 mt-6">2 Videos, 1 Audio, 1 Notebook</div>
                        <div className=" mt-8 ">
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Video: Getting Started and Introductions
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <span className=" bg-primary text-white px-3 rounded">Preview</span>
                              <img src="" alt=" " />
                            </div>
                          </Link>
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Video: Getting Started and Introductions
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <span className=" bg-primary text-white px-3 rounded">Preview</span>
                              <img src="" alt=" " />
                            </div>
                          </Link>
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Audio: Connectig Throgh Technology
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <img src="" alt=" " />
                            </div>
                          </Link>
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Video: Connectig Throgh Technology
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <img src="" alt=" " />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li>
                      <button type="button" className="accrodain-button">
                        <span className="icon-pm fle x-none" />
                        <span className=" flex-1">Start from Sketch Design</span>
                        <div className=" flex-none extra-text hidden sm:block ">
                          3 Lectures, 34:51 min
                        </div>
                      </button>
                      <div className="content hidden">
                        <div className=" text-xl font-semibold text-black mb-2">Intermediate Level</div>
                        <p>
                          This tutorial will help you learn quickly and thoroughly. Lorem ipsum is dummy text used in laying out print, graphic or
                          web designs
                        </p>
                        <div className=" text-xl font-semibold text-black mb-2 mt-6">2 Videos, 1 Audio, 1 Notebook</div>
                        <div className=" mt-8 ">
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Video: Getting Started and Introductions
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <span className=" bg-primary text-white px-3 rounded">Preview</span>
                              <img src="" alt=" " />
                            </div>
                          </Link>
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Video: Getting Started and Introductions
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <span className=" bg-primary text-white px-3 rounded">Preview</span>
                              <img src="" alt=" " />
                            </div>
                          </Link>
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Audio: Connectig Throgh Technology
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <img src="" alt=" " />
                            </div>
                          </Link>
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Video: Connectig Throgh Technology
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <img src="" alt=" " />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li>
                      <button type="button" className="accrodain-button">
                        <span className="icon-pm fle x-none" />
                        <span className=" flex-1">Getting Help &amp; Support in Seller Central</span>
                        <div className=" flex-none extra-text hidden sm:block ">
                          3 Lectures, 34:51 min
                        </div>
                      </button>
                      <div className="content hidden">
                        <div className=" text-xl font-semibold text-black mb-2">Intermediate Level</div>
                        <p>
                          This tutorial will help you learn quickly and thoroughly. Lorem ipsum is dummy text used in laying out print, graphic or
                          web designs
                        </p>
                        <div className=" text-xl font-semibold text-black mb-2 mt-6">2 Videos, 1 Audio, 1 Notebook</div>
                        <div className=" mt-8 ">
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Video: Getting Started and Introductions
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <span className=" bg-primary text-white px-3 rounded">Preview</span>
                              <img src="" alt=" " />
                            </div>
                          </Link>
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Video: Getting Started and Introductions
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <span className=" bg-primary text-white px-3 rounded">Preview</span>
                              <img src="" alt=" " />
                            </div>
                          </Link>
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Audio: Connectig Throgh Technology
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <img src="" alt=" " />
                            </div>
                          </Link>
                          <Link to="#" className=" flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0">
                            <div className="flex-1 flex">
                              <span className="flex-none  mr-2">
                                <img src="" alt=" " />
                              </span>
                              <span className="flex-1">Video: Connectig Throgh Technology
                              </span>
                            </div>
                            <div className="flex-none flex space-x-3">
                              <img src="" alt=" " />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="tab3" className="tab-content" style={{display: 'none'}}>
                <div className=" bg-[#F8F8F8] rounded-md p-8">
                  <div className="md:flex space-x-5 mb-8">
                    <div className="h-[310px] w-[270px] flex-none rounded mb-5 md:mb-0">
                      <img src="assets/images/all-img/ux.png" alt=" " className=" w-full h-full object-cover  rounded" />
                    </div>
                    <div className="flex-1">
                      <div className="max-w-[300px]">
                        <h4 className=" text-[34px] font-bold leading-[51px]">Daniel Smith</h4>
                        <div className=" text-primary mb-6">
                          User Experience Designer
                        </div>
                        <ul className=" list space-y-4">
                          <li className=" flex space-x-3">
                            <img src="" alt=" " />
                            <div>
                              65+ Courses
                            </div>
                          </li>
                          <li className=" flex space-x-3">
                            <img src="" alt=" " />
                            <div>
                              2k+ Student Learned
                            </div>
                          </li>
                          <li className=" flex space-x-3">
                            <img src="" alt=" " />
                            <div>
                              547+ Reviews
                            </div>
                          </li>
                          <li className=" flex space-x-3">
                            <img src="" alt=" " />
                            <div>
                              4.9 Average Rating
                            </div>
                          </li>
                        </ul>
                        <ul className=" flex space-x-3 mt-8">
                          <li className>
                            <Link to="#"><img src="assets/images/social/fb.svg" alt=" " />
                            </Link>
                          </li>
                          <li className>
                            <Link to="#"><img src="assets/images/social/ln.svg" alt=" " />
                            </Link>
                          </li>
                          <li className>
                            <Link to="#"><img src="assets/images/social/youtube.svg" alt=" " />
                            </Link>
                          </li>
                          <li className>
                            <Link to="#"><img src="assets/images/social/instra.svg" alt=" " />
                            </Link>
                          </li>
                          <li className>
                            <Link to="#"><img src="assets/images/social/twiiter.svg" alt=" " />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p>
                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered alt=" "eration in some form,
                    by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of
                    Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum
                    generators on the Internet tend.
                  </p>
                </div>
              </div>
              <div id="tab4" className="tab-content" style={{display: 'none'}}>
                <div>
                  <div className="grid grid-cols-12 gap-5">
                    <div className="md:col-span-8 col-span-12">
                      <div className="flex items-center space-x-4  mb-5 last:mb-0 ">
                        <div className="flex-none">
                          <div className="flex space-x-1 text-xl  ">
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="progressbar-group flex items-center space-x-4">
                            <div className="rounded-[2px] overflow-hidden bg-opacity-10 bg-black h-[6px] relative flex-1">
                              <div className="ani  h-[6px] bg-secondary block absolute left-0 top-1/2 -translate-y-1/2 " data-progress={40} style={{width: '40%'}} />
                            </div>
                            <div className="flex-none">
                              <span className=" block mb-2  font-semibold">40%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4  mb-5 last:mb-0 ">
                        <div className="flex-none">
                          <div className="flex space-x-1 text-xl  ">
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-[#E6E6E6]" />
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="progressbar-group flex items-center space-x-4">
                            <div className="rounded-[2px] overflow-hidden bg-opacity-10 bg-black h-[6px] relative flex-1">
                              <div className="ani  h-[6px] bg-secondary block absolute left-0 top-1/2 -translate-y-1/2 " data-progress={10} style={{width: '10%'}} />
                            </div>
                            <div className="flex-none">
                              <span className=" block mb-2  font-semibold">10%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4  mb-5 last:mb-0 ">
                        <div className="flex-none">
                          <div className="flex space-x-1 text-xl  ">
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-[#E6E6E6]" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-[#E6E6E6]" />
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="progressbar-group flex items-center space-x-4">
                            <div className="rounded-[2px] overflow-hidden bg-opacity-10 bg-black h-[6px] relative flex-1">
                              <div className="ani  h-[6px] bg-secondary block absolute left-0 top-1/2 -translate-y-1/2 " data-progress={0} style={{width: '0%'}} />
                            </div>
                            <div className="flex-none">
                              <span className=" block mb-2  font-semibold">0%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4  mb-5 last:mb-0 ">
                        <div className="flex-none">
                          <div className="flex space-x-1 text-xl  ">
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-[#E6E6E6]" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-[#E6E6E6]" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-[#E6E6E6]" />
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="progressbar-group flex items-center space-x-4">
                            <div className="rounded-[2px] overflow-hidden bg-opacity-10 bg-black h-[6px] relative flex-1">
                              <div className="ani  h-[6px] bg-secondary block absolute left-0 top-1/2 -translate-y-1/2 " data-progress={0} style={{width: '0%'}} />
                            </div>
                            <div className="flex-none">
                              <span className=" block mb-2  font-semibold">0%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4  mb-5 last:mb-0 ">
                        <div className="flex-none">
                          <div className="flex space-x-1 text-xl  ">
                            <iconify-icon icon="heroicons:star-20-solid" className="text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-[#E6E6E6]" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-[#E6E6E6]" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-[#E6E6E6]" />
                            <iconify-icon icon="heroicons:star-20-solid" className="text-[#E6E6E6]" />
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="progressbar-group flex items-center space-x-4">
                            <div className="rounded-[2px] overflow-hidden bg-opacity-10 bg-black h-[6px] relative flex-1">
                              <div className="ani  h-[6px] bg-secondary block absolute left-0 top-1/2 -translate-y-1/2 " data-progress={0} style={{width: '0%'}} />
                            </div>
                            <div className="flex-none">
                              <span className=" block mb-2  font-semibold">0%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="md:col-span-4 col-span-12">
                      <div className="bg-white min-h-[219px] p-6 flex flex-col justify-center items-center shadow-box7 rounded space-y-3">
                        <h2>
                          4.9
                        </h2>
                        <div className="flex space-x-3">
                          <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                          <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                          <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                          <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                          <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                        </div>
                        <span className=" block">(2 Review)</span>
                      </div>
                    </div>
                  </div>
                  {/* review comments */}
                  <div className=" mt-8">
                    <h4 className=" text-xl font-bold text-black">Reviews</h4>
                    <ul className=" list space-y-6 mt-6">
                      <li className=" flex space-x-6 ">
                        <div className="flex-none">
                          <div className="h-[72px] w-[72px] rounded-full">
                            <img src="assets/images/all-img/cmnt-1.png" alt=" " className=" object-cover w-full h-full" />
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="flex space-x-3 mb-4">
                            <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                          </div>
                          <p>
                            There are many variations of passages of Lorem Ipsum available, but the
                            majority have suffered alt=" "eration.
                          </p>
                          <div className="author mt-4">
                            <span className="block text-xl font-bold text-black">Daniel Smith</span>
                            <span className="block">Jan 24, 2022</span>
                          </div>
                        </div>
                      </li>
                      <li className=" flex space-x-6 ">
                        <div className="flex-none">
                          <div className="h-[72px] w-[72px] rounded-full">
                            <img src="assets/images/all-img/cmnt-2.png" alt=" " className=" object-cover w-full h-full" />
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="flex space-x-3 mb-4">
                            <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                            <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                          </div>
                          <p>
                            There are many variations of passages of Lorem Ipsum available, but the
                            majority have suffered alt=" "eration.
                          </p>
                          <div className="author mt-4">
                            <span className="block text-xl font-bold text-black">Daniel Smith</span>
                            <span className="block">Jan 24, 2022</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:col-span-4 col-span-12">
        <div className="sidebarWrapper space-y-[30px]">
          <div className="wdiget custom-text space-y-5 ">
            <Link to="h-[220px]  rounded relative block" href="#">
              <img src="assets/images/all-img/thumb.png" alt=" " className=" block w-full h-full object-cover rounded " />
            </Link>
            <h3>Free</h3>
            <button className="btn btn-primary w-full text-center ">
              Enroll Now
            </button>
            <ul className="list  ">
              <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                <div className="flex-1 space-x-3 flex">
                  <img src="" alt=" " />
                  <div className=" text-black font-semibold">Instructor</div>
                </div>
                <div className="flex-none">
                  Daniel Smith
                </div>
              </li>
              <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                <div className="flex-1 space-x-3 flex">
                  <img src="" alt=" " />
                  <div className=" text-black font-semibold">Lectures</div>
                </div>
                <div className="flex-none">
                  23
                </div>
              </li>
              <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                <div className="flex-1 space-x-3 flex">
                  <img src="" alt=" " />
                  <div className=" text-black font-semibold">Duration</div>
                </div>
                <div className="flex-none">
                  2Hr 36Minutes
                </div>
              </li>
              <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                <div className="flex-1 space-x-3 flex">
                  <img src="" alt=" " />
                  <div className=" text-black font-semibold">Enrolled</div>
                </div>
                <div className="flex-none">
                  2k Students
                </div>
              </li>
              <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                <div className="flex-1 space-x-3 flex">
                  <img src="" alt=" " />
                  <div className=" text-black font-semibold">Course level</div>
                </div>
                <div className="flex-none">
                  Intermediate
                </div>
              </li>
              <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                <div className="flex-1 space-x-3 flex">
                  <img src="" alt=" " />
                  <div className=" text-black font-semibold">Language</div>
                </div>
                <div className="flex-none">
                  English
                </div>
              </li>
            </ul>
            <ul className="flex space-x-4 items-center pt-3 ">
              <li className=" text-black font-semibold">
                Share On:
              </li>
              <li>
                <Link to="#" className="flex h-10 w-10">
                  <img src="" alt=" " />
                </Link>
              </li>
              <li>
                <Link to="#" className="flex h-10 w-10">
                  <img src="" alt=" " />
                </Link>
              </li>
              <li>
                <Link to="#" className="flex h-10 w-10">
                  <img src="" alt=" " />
                </Link>
              </li>
              <li>
                <Link to="#" className="flex h-10 w-10">
                  <img src="" alt=" " />
                </Link>
              </li>
            </ul>
          </div>
          <div className="wdiget">
            <h4 className=" widget-title">Related Courses</h4>
            <ul className="list">
              <li className=" flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 last:border-0 border-b">
                <div className="flex-none ">
                  <div className="h-20 w-20  rounded">
                    <img src="assets/images/all-img/rc-1.png" alt=" " className=" w-full h-full object-cover rounded" />
                  </div>
                </div>
                <div className="flex-1 ">
                  <div className="flex space-x-3 mb-2">
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                  </div>
                  <div className="mb-1 font-semibold text-black">
                    Greatest Passion In...
                  </div>
                  <span className=" text-secondary font-semibold">$38.00</span>
                </div>
              </li>
              <li className=" flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 last:border-0 border-b">
                <div className="flex-none ">
                  <div className="h-20 w-20  rounded">
                    <img src="assets/images/all-img/rc-2.png" alt=" " className=" w-full h-full object-cover rounded" />
                  </div>
                </div>
                <div className="flex-1 ">
                  <div className="flex space-x-3 mb-2">
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                  </div>
                  <div className="mb-1 font-semibold text-black">
                    Greatest Passion In...
                  </div>
                  <span className=" text-secondary font-semibold">$38.00</span>
                </div>
              </li>
              <li className=" flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 last:border-0 border-b">
                <div className="flex-none ">
                  <div className="h-20 w-20  rounded">
                    <img src="assets/images/all-img/rc-3.png" alt=" " className=" w-full h-full object-cover rounded" />
                  </div>
                </div>
                <div className="flex-1 ">
                  <div className="flex space-x-3 mb-2">
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                    <iconify-icon icon="heroicons:star-20-solid" className=" text-tertiary" />
                  </div>
                  <div className="mb-1 font-semibold text-black">
                    Greatest Passion In...
                  </div>
                  <span className=" text-secondary font-semibold">$38.00</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    
    </>
  )
}

export default CoursePage