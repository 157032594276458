import {Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import Courses from "./pages/Courses";
import DarUlIfta from "./pages/DarUlIfta";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import CoursePage from "./pages/CoursePage";
import PageNotFound from "./pages/404";

export default function Switch() {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route path="/" element={<Home />} />
      <Route path="courses" element={<Courses />} />
      <Route path="darulifta" element={<DarUlIfta />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<ContactUs />} />
      <Route path="courses/:id" element={<CoursePage />} />
      {/* <Route path="courses">
        <Route index element={<Courses />} />
        <Route path="courses/:id" component={CoursePage} />
      </Route> */}
    </Routes>
  );
}