// Loading.js

export default function Loading() {
    return (
      <div className="flex justify-center items-center py-20">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    );
  }