import { Link } from "react-router-dom";

function Navbar() {
  return (
    <>
    <div className="flex justify-center bg-theme-blue text-white">
  <nav className="self-center w-full md:w-[750px] lg:w-[970px] xl:w-[1170px] max-w-7xl ">
    <div className="flex flex-wrap justify-between items-center">
      <span className="px-10">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="lg:hidden my-4 w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </span>
      <ul className="hidden lg:flex justify-center my-4  items-center text-sm md:text-[18px] font-bold md:px-10">
        <li className="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-2 md:px-5">
          <Link to="/">Home</Link>
        </li>
        <li className="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-2 md:px-5">
          <Link to="/courses">Courses</Link>
        </li>
        <li className="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-2 md:px-5">
          <Link to="/darulifta">Dar ul Ifta</Link>
        </li>
        <li className="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-2 md:px-5">
          <Link to="/contact">Contact Us</Link>
        </li>
        <li className="hover:underline underline-offset-4 decoration-2 decoration-white py-2 rounded-lg px-2 md:px-5">
          <Link to="/about">About</Link>
        </li>

      </ul>
      <span className="px-10">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="my-4 w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
        </svg>
      </span>
    </div>
  </nav>
</div>

    
    </>
  )
}

export default Navbar