import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter as Router } from "react-router-dom";
import App from "./App.js";
import "./index.css";
import "./i18n/i18n.js";
import Loading from "./components/Loading.js";


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Router>
    <React.Suspense fallback={<Loading />}>
      <App />
    </React.Suspense>
    </Router>
  </React.StrictMode>
);
