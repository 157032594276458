import Routes from "./Routes";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";


function App() {
  console.clear()
  return (
    <>
    <div className="bg-gray-200 dark:bg-Dark-900">
      <Header />
      <Routes />
      <Footer />
      </div>
    </>
  );
}

export default App;