import { useState } from 'react';
import { Link } from 'react-router-dom';


function Category({ category }) {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);
  const hasChildren = category.children?.length > 0;

  return (
    <div className='ml-6'>
      <div className='flex flex-row items-center justify-between font-bold text-lg' onClick={toggle}>
        <Link to={category.cate_link}>{category.cate_name}{hasChildren && (<span className='font-medium text-sm'> {`(${category.children.length})`}</span>)}</Link>

        {hasChildren && (open ?
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className="mx-2 w-4 h-4 cursor-pointer">
            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
          </svg>
          :
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className="mx-2 w-4 h-4 cursor-pointer">
            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        )}
      </div>

      {open &&
        category.children.map(child => (
          <Category key={child.id} category={child} />
        ))}
    </div>
  );
}

function Categories({ categories }) {
  return (
    <div>
      {categories.map(category => (
        <Category key={category.id} category={category} />
      ))}
    </div>
  );
}

export default Categories;
