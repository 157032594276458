import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import CourseCard from "../components/CourseCard";
import api from '../api/api';
import Loading from '../components/Loading';
// import { Link } from "react-router-dom";
function Courses() {

  const [courses, setCourses] = useState([]);

  const fetchCourses = async () => {
    try {
      const response = await api.get('/courses');
      setCourses(response.data.courses);
    } catch (error) {
      console.error(error);
    }
  }
  
  useEffect(() => {
    fetchCourses();
  }, []);

  if (!courses) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Courses - Noor Centre</title>
      </Helmet>

      <div className="my-4 md:my-5 text-gray-900 dark:text-white">
        <h2 className="mb-4 text-center text-2xl font-bold md:mb-6 lg:text-3xl">Courses</h2>
        <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">This is a section of some simple filler text, also known as placeholder text. It shares some characteristics of a real written text but is random or otherwise generated.</p>
      </div>
      <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 px-5 py-5">
        {courses.map((courses, i) => (
          <CourseCard key={i} course={courses} />
        ))}
        <div>
        </div>
      </div>
    </>
  );
}

export default Courses;
