import LanguageSelector from "../components/language-selector";
import ThemeSwitch from "../components/themeSwitch";
import Navbar from "../layouts/Navbar";
// import moment from 'moment';
import moment from 'moment-hijri';
function Header() {
  moment.locale('ur');
  var hijriDate = moment();
  hijriDate = hijriDate.subtract(1, "days");
  hijriDate = hijriDate.format("iMMMM-iD-iYYYY");
  return (
    <header>
      <div className="flex justify-center items-center top-0 h-14 md:h-10 bg-theme-green dark:text-white text-Dark-800">
        <div className="flex flex-wrap justify-center md:justify-between px-10 items-center w-full md:w-[750px] lg:w-[970px] xl:w-[1170px]">
          <div className="px-0 md:px-2">
            {moment().format('DD-MMMM-YYYY') + " / " + hijriDate}
          </div>
          <div className="px-0 md:px-2">Prayer Time / نماز کے اوقات</div>
          <div className="flex px-0 md:px-2">
            <LanguageSelector />
            <ThemeSwitch />
          </div>
        </div>
      </div>
      <div className="flex justify-center w-full bg-gray-200 h-36">
        <div className="flex flex-wrap justify-center md:justify-between items-center w-full md:w-[750px] lg:w-[970px] xl:w-[1170px]">
          <div className="hidden md:block px-10">Email</div>
          <div className="">Logo</div>
          <div className="hidden md:block px-10">Call us</div>
        </div>
      </div>
      <Navbar />
    </header>
  );
}

export default Header;
