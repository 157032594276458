import React from 'react'
import { Link } from 'react-router-dom'

function FatwaCardEng({ fatwa }) {
  return (
    <div className="border border-gray-700 dark:border-gray-500 my-2 p-4 rounded-lg text-justify bg-white dark:bg-Dark-700">
            <Link to={`/darulifta/${fatwa.fatwa_id}`} className="text-lg font-bold text-black dark:text-gray-300 hover:cursor-pointer "><span className="font-extrabold">Question: </span>${fatwa.fatwa_question}</Link>
          </div>
  )
}

export default FatwaCardEng